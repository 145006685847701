<template>
  <div class="activites">
    <div class="bandeau"></div>
    <div class="voileGris">
      <h2><span v-if="$store.state.lang == 'fr'">Activités</span><span v-if="$store.state.lang == 'us'">Activities</span></h2>
    </div>

    <div class="container text-center my-5">
      <h2><span v-if="$store.state.lang == 'fr'">Que faire en Normandie ?</span><span v-if="$store.state.lang == 'us'">What to do in Normandy ?</span></h2>
    </div>
    <iframe width="100%" height="700px" frameborder="0" allowfullscreen src="//umap.openstreetmap.fr/fr/map/carte-activites-lentre-deux-phares_565218?scaleControl=false&miniMap=true&scrollWheelZoom=true&zoomControl=true&allowEdit=false&moreControl=true&searchControl=null&tilelayersControl=null&embedControl=null&datalayersControl=true&onLoadPanel=none&captionBar=false"></iframe>
  </div>
</template>

<style scoped>
.bandeau{
  width: 100%;
  height: 60vh;
  background-image: url("../assets/omahabeach.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.voileGris{
  width: 100%;
  height: 60vh;
  background-color: #848b9a54;
  position: absolute;
  top:0;
  text-align: center;
}

.voileGris h2{
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 70px;
  text-shadow: black 5px 5px 5px;
}

@media screen and (max-width: 800px) {
  .voileGris h2{
    top: 50%;
    left: 5%;
    transform: translate(0, -50%);
    font-size: 40px;
  }
}
</style>

<script>
export default {
  name: 'Activites',
  data () {
    return {}
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {}
}
</script>
