<template>
  <div class="p404">
    <div class="case text-center">
      <p class="titre404">404</p>
      <p class="txt404">Page not found.</p>
    </div>
  </div>
</template>

<style scoped>
.p404{
  height: 100vh;
  background-color: #313338;
}

.case{
  color: #fff;
  border: #848B9A solid 1px;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "raleway";
  padding: 100px 0;
  width: 70%;
}

.titre404{
  color: #848B9A;
  font-size: 120px;
  font-weight: bold;
}

.txt404{
  font-size: 40px;
}
</style>

<script>
export default {
  name: '404',
  data () {
    return {}
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {}
}
</script>
