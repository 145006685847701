<template>
  <div class="leboisflotte">
    <div class="bandeau"></div>
    <div class="voileGris">
      <h2>Le Bois Flotté</h2>
    </div>
    <div class="container presentation">
      <div class="row">
        <div class="col-md bloc blocText">
          <h2>Le Bois Flotté</h2>
          <div v-if="$store.state.lang == 'fr'">
            <p>
              Vous rêvez d’un séjour authentique et confortable dans une maison de charme à Port-en-Bessin ? Découvrez la location 4 étoiles “Le Bois Flotté”, une demeure en pierres apparentes située à 100 m du port, idéale pour profiter des ambiances maritimes et du patrimoine normand.
            </p>
            <p>
              Dans cette maison cosy et décorée avec soin, vous trouverez tout le confort moderne et l’esprit bois flotté qui vous invite à la détente. La location peut accueillir jusqu’à 5 personnes, avec :
            </p>
            <p>
              Au rez-de-chaussée :
              <br>
              - Une grande pièce à vivre avec une cuisine ouverte entièrement équipée (lave-linge/séchant, plaque induction, four pyrolyse, micro-ondes, réfrigérateur-congélateur, lave-vaisselle, machine à café Senseo, cafetière filtre, bouilloire, toaster, fond de maison…)
              <br>
              - Un séjour/salon avec un canapé convertible de qualité (140x190) et un espace repas
            </p>
            <p>
              A l’étage :
              <br>
              - Une salle d’eau avec wc (douche italienne) et vasque
              <br>
              - Une grande chambre avec un lit 160x200 et une autre chambre avec deux lits 90x190
            </p>
            <p>
              Depuis la maison, vous pourrez accéder facilement au port et aux commerces à pied. Vous pourrez également stationner gratuitement dans la rue ou dans les parkings à proximité.
            </p>
          </div>

          <div v-if="$store.state.lang == 'us'">
            <p>
              Are you dreaming of an authentic and comfortable stay in a charming house in Port-en-Bessin? Discover the 4-star rental “Le Bois Flotté”, a house with exposed stone walls located 100 m from the port, ideal for enjoying the maritime atmosphere and Norman heritage.
            </p>
            <p>
              In this cozy house decorated with care, you will find all modern comforts and the driftwood spirit that invites you to relax. The rental can accommodate up to 5 people, with:
            </p>
            <p>
              On the ground floor:
              <br>
              - A large living room with a fully equipped open kitchen (washing machine/dryer, induction hob, pyrolysis oven, microwave, fridge-freezer, dishwasher, Senseo coffee machine, filter coffee maker, kettle, toaster, home base…)
              <br>
              - A living room with a high-quality convertible sofa (140x190) and a dining area
            </p>
            <p>
              Upstairs:
              <br>
              - A bathroom with toilet (Italian shower) and washbasin
              <br>
              - A large bedroom with a 160x200 bed and another bedroom with two 90x190 beds
            </p>
            <p>
              From the house, you can easily access the port and shops on foot. You can also park for free on the street or in nearby car parks.
            </p>
          </div>
        </div>
        <div class="col-md bloc blocImage"></div>
      </div>
    </div>

    <div class="container equipement">
      <h2 class="text-center" ><span v-if="$store.state.lang == 'fr'">Equipement</span><span v-if="$store.state.lang == 'us'">Amenities</span></h2>

      <div class="row blocEquipement" v-if="$store.state.lang == 'fr'">
      <div class="col-md">
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-parking"></i></span> Parking gratuit sur place</p>
        </div>
        <div class="ligne ligneBlanche">
          <p><span class="conteneurSvg"><i class="fas fa-wifi"></i></span> Wifi (fibre optique)</p>
        </div>
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-pump-soap"></i></span> Équipements de base</p>
        </div>
        <div class="ligne ligneBlanche">
          <p><span class="conteneurSvg"><i class="fas fa-socks"></i></span> Fer à repasser</p>
        </div>
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-wind"></i></span> Sèche-cheveux</p>
        </div>
      </div>
      <div class="col-md">
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-desktop"></i></span> Espace de travail dédié</p>
        </div>
        <div class="ligne ligneBlanche">
          <p><span class="conteneurSvg"><i class="fas fa-utensils"></i></span> Cuisine</p>
        </div>
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-tshirt"></i></span> Cintres</p>
        </div>
        <div class="ligne ligneBlanche">
          <p><span class="conteneurSvg"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dryer" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-dryer fa-w-14"><path fill="currentColor" d="M384 0H64A64 64 0 0 0 0 64v416a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a64 64 0 0 0-64-64zM184 64a24 24 0 1 1-24 24 24 24 0 0 1 24-24zM64 88a24 24 0 1 1 24 24 24 24 0 0 1-24-24zm160 360a144 144 0 1 1 144-144 144 144 0 0 1-144 144zm-8-176c0-22.64-11.95-34.59-20.69-43.33-5.82-5.82-9.24-9.45-10.6-14.62A8 8 0 0 0 177 208h-16.29a8.16 8.16 0 0 0-8 9.53c2.77 16.64 12.51 26.38 19.93 33.8C180.53 259.17 184 263 184 272s-3.47 12.86-11.31 20.7C164 301.47 152 313.42 152 336.06s11.95 34.56 20.69 43.28c5.82 5.82 9.23 9.44 10.6 14.59A8 8 0 0 0 191 400h16.32a8.16 8.16 0 0 0 8-9.53c-2.77-16.61-12.51-26.35-19.93-33.75C187.47 348.88 184 345 184 336.06s3.47-12.86 11.31-20.7C204.05 306.62 216 294.67 216 272zm80 0c0-22.64-11.95-34.59-20.69-43.33-5.82-5.82-9.24-9.45-10.6-14.62A8 8 0 0 0 257 208h-16.29a8.16 8.16 0 0 0-8 9.53c2.77 16.64 12.51 26.38 19.93 33.8C260.53 259.17 264 263 264 272s-3.47 12.86-11.31 20.7C244 301.47 232 313.42 232 336.06s11.95 34.56 20.69 43.28c5.82 5.82 9.23 9.44 10.6 14.59A8 8 0 0 0 271 400h16.32a8.16 8.16 0 0 0 7.95-9.53c-2.77-16.61-12.51-26.35-19.93-33.75C267.47 348.88 264 345 264 336.06s3.47-12.86 11.31-20.7C284.05 306.62 296 294.67 296 272z" class=""></path></svg></span> Lave-linge/Sèche-linge</p>
        </div>
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="tv-retro" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-tv-retro fa-w-16"><path fill="currentColor" d="M400 244v-8c0-6.6 5.4-12 12-12h8c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12h-8c-6.6 0-12-5.4-12-12zm12 76h8c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12zm-36-136.5s-1.5-7.5-144-7.5-144.5 7.5-144.5 7.5S80 184 80 288s7.5 104.5 7.5 104.5S88 400 232 400s144-7.5 144-7.5 7.5-.5 7.5-104.5-7.5-104.5-7.5-104.5zM512 144v288c0 26.5-21.5 48-48 48h-16v32h-48l-10.7-32H122.7L112 512H64v-32H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h140.9l-54-55.2c-9.3-9.5-9.1-24.7.3-33.9 9.5-9.3 24.7-9.1 33.9.3L256 96l86.9-88.8c9.3-9.5 24.5-9.6 33.9-.3 9.5 9.3 9.6 24.5.3 33.9l-54 55.2H464c26.5 0 48 21.5 48 48zm-48 0H48v288h416V144z" class=""></path></svg></span> Télévision 4K ~150 chaines</p>
        </div>
      </div>
      </div>

      <div class="row blocEquipement" v-if="$store.state.lang == 'us'">
        <div class="col-md">
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-parking"></i></span> Free parking on premises</p>
        </div>
        <div class="ligne ligneBlanche">
          <p><span class="conteneurSvg"><i class="fas fa-wifi"></i></span> Wifi (optical fiber)</p>
        </div>
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-pump-soap"></i></span> Essentials</p>
        </div>
        <div class="ligne ligneBlanche">
          <p><span class="conteneurSvg"><i class="fas fa-socks"></i></span> Iron</p>
        </div>
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-wind"></i></span> Hair dryer</p>
        </div>
      </div>
      <div class="col-md">
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-desktop"></i></span> Dedicated workspace</p>
        </div>
        <div class="ligne ligneBlanche">
          <p><span class="conteneurSvg"><i class="fas fa-utensils"></i></span> Kitchen</p>
        </div>
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><i class="fas fa-tshirt"></i></span> Hangers</p>
        </div>
        <div class="ligne ligneBlanche">
          <p><span class="conteneurSvg"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="dryer" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-dryer fa-w-14"><path fill="currentColor" d="M384 0H64A64 64 0 0 0 0 64v416a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a64 64 0 0 0-64-64zM184 64a24 24 0 1 1-24 24 24 24 0 0 1 24-24zM64 88a24 24 0 1 1 24 24 24 24 0 0 1-24-24zm160 360a144 144 0 1 1 144-144 144 144 0 0 1-144 144zm-8-176c0-22.64-11.95-34.59-20.69-43.33-5.82-5.82-9.24-9.45-10.6-14.62A8 8 0 0 0 177 208h-16.29a8.16 8.16 0 0 0-8 9.53c2.77 16.64 12.51 26.38 19.93 33.8C180.53 259.17 184 263 184 272s-3.47 12.86-11.31 20.7C164 301.47 152 313.42 152 336.06s11.95 34.56 20.69 43.28c5.82 5.82 9.23 9.44 10.6 14.59A8 8 0 0 0 191 400h16.32a8.16 8.16 0 0 0 8-9.53c-2.77-16.61-12.51-26.35-19.93-33.75C187.47 348.88 184 345 184 336.06s3.47-12.86 11.31-20.7C204.05 306.62 216 294.67 216 272zm80 0c0-22.64-11.95-34.59-20.69-43.33-5.82-5.82-9.24-9.45-10.6-14.62A8 8 0 0 0 257 208h-16.29a8.16 8.16 0 0 0-8 9.53c2.77 16.64 12.51 26.38 19.93 33.8C260.53 259.17 264 263 264 272s-3.47 12.86-11.31 20.7C244 301.47 232 313.42 232 336.06s11.95 34.56 20.69 43.28c5.82 5.82 9.23 9.44 10.6 14.59A8 8 0 0 0 271 400h16.32a8.16 8.16 0 0 0 7.95-9.53c-2.77-16.61-12.51-26.35-19.93-33.75C267.47 348.88 264 345 264 336.06s3.47-12.86 11.31-20.7C284.05 306.62 296 294.67 296 272z" class=""></path></svg></span> Washing machine/ Dryer</p>
        </div>
        <div class="ligne ligneGrise">
          <p><span class="conteneurSvg"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="tv-retro" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-tv-retro fa-w-16"><path fill="currentColor" d="M400 244v-8c0-6.6 5.4-12 12-12h8c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12h-8c-6.6 0-12-5.4-12-12zm12 76h8c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12zm-36-136.5s-1.5-7.5-144-7.5-144.5 7.5-144.5 7.5S80 184 80 288s7.5 104.5 7.5 104.5S88 400 232 400s144-7.5 144-7.5 7.5-.5 7.5-104.5-7.5-104.5-7.5-104.5zM512 144v288c0 26.5-21.5 48-48 48h-16v32h-48l-10.7-32H122.7L112 512H64v-32H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h140.9l-54-55.2c-9.3-9.5-9.1-24.7.3-33.9 9.5-9.3 24.7-9.1 33.9.3L256 96l86.9-88.8c9.3-9.5 24.5-9.6 33.9-.3 9.5 9.3 9.6 24.5.3 33.9l-54 55.2H464c26.5 0 48 21.5 48 48zm-48 0H48v288h416V144z" class=""></path></svg></span> 4K TV ~150 channels</p>
        </div>
      </div>
    </div>

      <div class="text-center">
        <button type="button" class="btn btn-perso" data-toggle="modal" data-target="#equipModal" v-if="$store.state.lang == 'fr'">
          Voir tout l'équipement
        </button>

        <button type="button" class="btn btn-perso" data-toggle="modal" data-target="#equipModal" v-if="$store.state.lang == 'us'">
          Show all
        </button>
      </div>
    </div>

    <div class="container-fluid cols masonry">
      <div class="box" v-for="image in images" :key="image.id" data-toggle="modal" data-target="#lightbox" @click="galerie(image.path, image.descr)"><img :src="image.path" alt="photo gite"></div>
    </div>

    <div class="container grille_tarifs">
      <h2 class="text-center" v-if="$store.state.lang == 'fr'">Tarifs</h2>
      <h2 class="text-center" v-if="$store.state.lang == 'us'">Prices</h2>
      <div class="warn">
        <p v-if="$store.state.lang == 'fr'">
          Le tarif <strong>minimum</strong> établi pour une nuitée est <strong>à partir de 130€</strong>. <br>
          Le <strong>tarif à la semaine</strong> est fixé<strong> à partir de 800€</strong>. <br><br>
          Pour toute demande d'information complémentaire, veuillez nous contacter.
        </p>

        <p v-if="$store.state.lang == 'us'">
          The <strong>minimum</strong> rate for one night is <strong>from €130</strong>.<br>
          The <strong>weekly rate</strong> is fixed <strong>from 800€</strong>.<br><br>
          For any request for additional information, please contact us.
        </p>
      </div>
      <p v-if="$store.state.lang == 'fr'" class="my-5 text-center">
        <span style="color: red;">Nettoyage renforcé lié au COVID-19 : <span style="text-decoration: underline; cursor:pointer" data-toggle="modal" data-target="#Covid19Modal">En savoir plus</span></span> <br>
        La <strong>taxe de Séjour est de 2.20 €</strong> par Nuitée pour les + de 18 ans. <br>
        <strong>Forfait ménage : 90€ (obligatoire)</strong> <br>
        <strong>La caution est de 300€</strong> et vous sera rendue à la sortie. <br>
        Nos tarifs comprennent le chauffage, l'électricité, l'eau, et le Wifi. <br> L'ensemble du linge de maison (draps, serviettes de toilette, gants, torchons, peignoirs) sont fournis. <br> Un fond de maison (café, thé, sucre, huile, vinaigre, sel et poivre) seront à votre disposition. Un cadeau de bienvenue vous sera offert. <br>
        Les lits seront prêts pour votre arrivée. <br>
        Stationnement gratuit
      </p>
      <p v-if="$store.state.lang == 'us'" class="my-5 text-center">
        <span style="color: red;">Reinforced cleaning due to COVID-19 : <span style="text-decoration: underline; cursor:pointer" data-toggle="modal" data-target="#Covid19Modal">Learn more</span></span> <br>
        The <strong>tourist tax is 2.20 €</strong> per Night for people over 18 years old. <br>
        <strong>Cleaning fee : 90€ (mandatory)</strong> <br>
        <strong>The bail is 300 €</strong> and will be returned upon departure. <br>
        Our prices include heating, electricity, water, Wifi, bed linen, household linen, bathroom linen, a home base (coffee, tea, sugar, oil, vinegar, salt and pepper) as well as a welcome gift. <br>
        The beds are made on arrival. <br>
        Free parking
      </p>
    </div>

    <div class="container-fluid map">
      <h2 class="text-center" v-if="$store.state.lang == 'fr'">Localisation</h2>
      <h2 class="text-center" v-if="$store.state.lang == 'us'">Location</h2>
      <div class="infoMap">
        <p><span class="adresse">27, rue de Bayeux</span> <br>
          14520 Port-en-Bessin-Huppain</p>
          <a v-if="$store.state.lang == 'fr'" :href="`${publicPath}accesLeBoisFlotte.pdf`" download="accesLeBoisFlotte">Télécharger le plan d'accès</a>
          <a v-if="$store.state.lang == 'us'" :href="`${publicPath}accesLeBoisFlotte.pdf`" download="accesLeBoisFlotte">Download the access map</a>
      </div>
      <iframe width="100%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=-0.7560074329376221%2C49.34479548836383%2C-0.7541835308074951%2C49.346240628018414&amp;layer=mapnik&amp;marker=49.34551718977267%2C-0.7550954818725586"></iframe>
    </div>

    <!--<div id="carouselAvis" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselAvis" data-slide-to="0" class="active"></li>
        <li data-target="#carouselAvis" data-slide-to="1"></li>
        <li data-target="#carouselAvis" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="quote">
            <p class="avis text-center">"We spent one week in this great appartment. (2 adults and 1 child). The location with harbour view, close to the beach, in the middle of the center is great. The appartment was very clean, comfortabel and well equiped. Geraldine, thanks a lot for your great services en hospitality. We had a great time."</p>
            <p class="signature text-center">
              Tine O.<br>
              Abritel
            </p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="quote">
            <p class="avis text-center">"Très bel endroit qui permet de profiter de l’ambiance du port. Très propre et décoré avec beaucoup de goût. Géraldine très attentionnée pour ses hôtes. A recommander sans hésitation. Merci."</p>
            <p class="signature text-center">
              P<br>
              AirBnB
            </p>
          </div>
        </div>
        <div class="carousel-item">
          <div class="quote">
            <p class="avis text-center">"Vue imprenable sur le port , A l intérieur tout est conçu pour que les hôtes de Geraldine se sentent bien et reçus comme à la maison . De délicieuses surprises vous attendent ainsi qu un fond de maison très pratique pour les courts séjours. La décoration a été choisie avec cœur et cela se ressent dans le confort du gîte. Nous reviendrons !"</p>
            <p class="signature text-center">
              Marie Sophie R.<br>
              Abritel
            </p>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselAvis" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselAvis" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>-->

    <div class="infos">
      <div class="infoBloc">
        <h3 class="text-center"><span v-if="$store.state.lang == 'fr'">Pour plus d'informations :</span> <span v-if="$store.state.lang == 'us'">For more information :</span></h3>
        <div class="conteneurLogo">
          <div class="logoImg"><a href="https://www.gites.fr/68179" target=_blank><img src="../assets/gitesfr.png" alt="logo gites.fr"></a></div>
          <div class="logoImg"><a href="https://www.airbnb.fr/rooms/11577901?source_impression_id=p3_1618084005_M5yQS0imvk4fGN%2F4&guests=1&adults=1" target=_blank><img src="../assets/airbnb.png" alt="logo airbnb"></a></div>
          <div class="logoImg"><a href="https://www.abritel.fr/location-vacances/p1979884" target=_blank><img src="../assets/abritel.png" alt="logo abritel"></a></div>
          <!--<div class="logoImg"><a href="" target=_blank><img src="../assets/nt.png" alt="logo normandie tourisme"></a></div>-->
          <div class="logoImg"><a href="https://www.clevacances.com/fr/locationvacances/normandie/calvados/portenbessinhuppain-4996/le_bois_flotte_4/61759" target=_blank><img src="../assets/clevacances.png" alt="logo clévacance"></a></div>
        </div>
        <p class="text-center"><span v-if="$store.state.lang == 'fr'">Ou :</span> <span v-if="$store.state.lang == 'us'">Or :</span></p>
        <div class="btn btn-perso text-center" @click="form()">
          <span v-if="$store.state.lang == 'fr'">Contactez nous !</span>
          <span v-if="$store.state.lang == 'us'">Contact us !</span>
        </div>
      </div>
    </div>

<!--Equipement Modal -->
<div class="modal fade" id="equipModal" tabindex="-1" role="dialog" aria-labelledby="equipModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-if="$store.state.lang == 'fr'">
          <h2>Equipement</h2>
          <h3>Salle de bain</h3>
          <p>Sèche-cheveux</p>
          <hr>
          <p>Eau chaude</p>
          <hr>
          <p>Gel douche</p>
          <hr>

          <h3>Chambre et linge</h3>
          <p>Équipements de base<br>
            <span class="pgris">Serviettes, draps, savon et papier toilette</span>
          </p>
          <hr>
          <p>Cintres</p>
          <hr>
          <p>Fer à repasser</p>
          <hr>
          <p>Sèche-linge</p>
          <hr>
          <p>Lave-linge</p>
          <hr>
          <p>Draps</p>
          <hr>
          <p>Oreillers et couvertures supplémentaires</p>
          <hr>

          <h3>Divertissement</h3>
          <p>Télévision</p>
          <hr>
          <p>Connexion Ethernet</p>
          <hr>

          <h3>Famille</h3>
          <p>Livres et jouets pour enfants</p>
          <hr>

          <h3>Chauffage et climatisation</h3>
          <p>Chauffage</p>
          <hr>

          <h3>Sécurité à la maison</h3>
          <p>Kit de premiers secours</p>
          <hr>
          <p>Détecteur de monoxyde de carbone</p>
          <hr>
          <p>Détecteur de fumée</p>
          <hr>

          <h3>Internet et bureau</h3>
          <p>Espace de travail dédié</p>
          <hr>
          <p>Wifi<br>
            <span class="pgris">Disponible dans l'ensemble du logement</span>
          </p>
          <hr>

          <h3>Cuisine et salle à manger</h3>
          <p>Cuisine<br>
            <span class="pgris">Espace où les voyageurs peuvent cuisiner</span>
          </p>
          <hr>
          <p>Table à induction</p>
          <hr>
          <p>Ustensiles de cuisine de base<br>
             <span class="pgris">Casseroles et poêles, huile, sel et poivre</span>
          </p>
          <hr>
          <p>Four</p>
          <hr>
          <p>Lave-vaisselle</p>
          <hr>
          <p>Four à micro-ondes</p>
          <hr>
          <p>Réfrigérateur</p>
          <hr>
          <p>Congélateur</p>
          <hr>
          <p>Cafetière</p>
          <hr>
          <p>Senseo</p>
          <hr>
          <p>Vaisselle et couverts</p>
          <hr>
          <p>Cuisinière</p>
          <hr>

          <h3>Caractéristiques de l'emplacement</h3>
          <p>Entrée privée<br>
          </p>
          <hr>

          <h3>Parking et installations</h3>
          <p>Parking gratuit sur place</p>
          <hr>
          <p>Parking gratuit dans la rue</p>
          <hr>

          <h3>Services</h3>
          <p>Dépôt de bagages autorisé<br>
            <span class="pgris">Pour le confort des voyageurs en cas d'arrivée anticipée</span>
          </p>
          <hr>
        </div>

        <div v-if="$store.state.lang == 'us'">
          <h2>Amenities</h2>
          <h3>Bathroom</h3>
          <p>Hair dryer</p>
          <hr>
          <p>Shower gel</p>
          <hr>
          <p>Hot water</p>
          <hr>

          <h3>Bedroom and laundry</h3>
          <p>Essentials<br>
            <span class="pgris">Towels, bed sheets, soap, and toilet paper</span>
          </p>
          <hr>
          <p>Hangers</p>
          <hr>
          <p>Iron</p>
          <hr>
          <p>Dryer</p>
          <hr>
          <p>Washer</p>
          <hr>
          <p>Extra pillows and blankets</p>
          <hr>
          <p>Bed linens</p>
          <hr>

          <h3>Entertainment</h3>
          <p>TV</p>
          <hr>
          <p>Ethernet connection</p>
          <hr>

          <h3>Family</h3>
          <p>Children’s books and toys</p>
          <hr>

          <h3>Heating and cooling</h3>
          <p>Heating</p>
          <hr>

          <h3>Home safety</h3>
          <p>First aid kit</p>
          <hr>
          <p>Carbon monoxide alarm</p>
          <hr>
          <p>Smoke alarm</p>
          <hr>

          <h3>Internet and office</h3>
          <p>Dedicated workspace</p>
          <hr>
          <p>Wifi<br>
            <span class="pgris">Available throughout the listing</span>
          </p>
          <hr>

          <h3>Kitchen and dining</h3>
          <p>Kitchen<br>
            <span class="pgris">Space where guests can cook their own meals</span>
          </p>
          <hr>
          <p>Coffee maker</p>
          <hr>
          <p>Senseo</p>
          <hr>
          <p>Cooking basics<br>
             <span class="pgris">Pots and pans, oil, salt and pepper</span>
          </p>
          <hr>
          <p>Microwave</p>
          <hr>
          <p>Dishwasher</p>
          <hr>
          <p>Baking sheet</p>
          <hr>
          <p>Refrigerator</p>
          <hr>
          <p>Freezer</p>
          <hr>
          <p>Oven</p>
          <hr>
          <p>Dishes and silverware</p>
          <hr>
          <p>Stove</p>
          <hr>

          <h3>Location features</h3>
          <p>Private entrance</p>
          <hr>

          <h3>Parking and facilities</h3>
          <p>Free parking on premises</p>
          <hr>
          <p>Free street parking</p>
          <hr>

          <h3>Services</h3>
          <hr>
          <p>Luggage dropoff allowed<br>
            <span class="pgris">For guests' convenience when they have early arrival</span>
          </p>
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Lightbox Modal -->
<div class="modal fade" id="lightbox" tabindex="-1" role="dialog" aria-labelledby="lightboxModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img :src="pathImage" alt="">
      </div>
      <div class="modal-footer text-center">
        <p>{{ descrImage }}</p>
      </div>
    </div>
  </div>
</div>

<!--COVID-19 Modal -->
<div class="modal fade" id="Covid19Modal" tabindex="-1" role="dialog" aria-labelledby="Covid19ModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="Covid19ModalLabel"><i class="fas fa-virus-slash"></i> <span v-if="$store.state.lang == 'fr'">Nettoyage renforcé</span><span v-if="$store.state.lang == 'us'">Reinforced cleaning</span></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-if="$store.state.lang == 'fr'">
          <p>En cette période particulière, nous mettons un point d’honneur à assurer la sécurité de nos clients.</p>
          <p>Ainsi, nous nous engageons à appliquer un processus de nettoyage en profondeur : un ensemble de normes élaboré en partenariat avec des experts, à utiliser durant la pandémie de COVID-19 et par la suite.</p>
          <p>Cette procédure sera systématiquement réalisée avant chaque séjour :</p>

          <ul class="list-unstyled row">
            <li class="media col-12 col-md-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sparkles" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sparkles fa-w-16 fa-2x mr-3"><g class="fa-group"><path fill="currentColor" d="M423.16 186.58L448 127l59.58-24.84a8 8 0 0 0 0-14.32L448 63 423.16 3.42a8 8 0 0 0-14.32 0L384 63l-59.58 24.84a8 8 0 0 0 0 14.32L384 127l24.84 59.58a8 8 0 0 0 14.32 0zm-14.32 136.84L384 383l-59.58 24.84a8 8 0 0 0 0 14.32L384 447l24.84 59.58a8 8 0 0 0 14.32 0L448 447l59.58-24.84a8 8 0 0 0 0-14.32L448 383l-24.84-59.58a8 8 0 0 0-14.32 0z" class="fa-secondary"></path><path fill="currentColor" d="M384 254.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 240.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 269a16.07 16.07 0 0 0 8.84-14.36z" class="fa-primary"></path></g></svg>
              <div class="media-body">
                <h5 class="mt-0 mb-1">Assainissement des surfaces</h5>
                <p>Assainissement de toutes les surfaces fréquemment touchées, jusqu'aux poignées de porte.</p>
              </div>
            </li>
            <li class="media col-12 col-md-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sparkles" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sparkles fa-w-16 fa-2x mr-3"><g class="fa-group"><path fill="currentColor" d="M423.16 186.58L448 127l59.58-24.84a8 8 0 0 0 0-14.32L448 63 423.16 3.42a8 8 0 0 0-14.32 0L384 63l-59.58 24.84a8 8 0 0 0 0 14.32L384 127l24.84 59.58a8 8 0 0 0 14.32 0zm-14.32 136.84L384 383l-59.58 24.84a8 8 0 0 0 0 14.32L384 447l24.84 59.58a8 8 0 0 0 14.32 0L448 447l59.58-24.84a8 8 0 0 0 0-14.32L448 383l-24.84-59.58a8 8 0 0 0-14.32 0z" class="fa-secondary"></path><path fill="currentColor" d="M384 254.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 240.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 269a16.07 16.07 0 0 0 8.84-14.36z" class="fa-primary"></path></g></svg>
              <div class="media-body">
                <h5 class="mt-0 mb-1">Utilisation de produits approuvés</h5>
                <p>Utilisation de produits nettoyants approuvés par des experts de la santé, comme des désinfectants contenant au moins 70 % d'alcool.</p>
              </div>
            </li>
            <li class="media col-12 col-md-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sparkles" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sparkles fa-w-16 fa-2x mr-3"><g class="fa-group"><path fill="currentColor" d="M423.16 186.58L448 127l59.58-24.84a8 8 0 0 0 0-14.32L448 63 423.16 3.42a8 8 0 0 0-14.32 0L384 63l-59.58 24.84a8 8 0 0 0 0 14.32L384 127l24.84 59.58a8 8 0 0 0 14.32 0zm-14.32 136.84L384 383l-59.58 24.84a8 8 0 0 0 0 14.32L384 447l24.84 59.58a8 8 0 0 0 14.32 0L448 447l59.58-24.84a8 8 0 0 0 0-14.32L448 383l-24.84-59.58a8 8 0 0 0-14.32 0z" class="fa-secondary"></path><path fill="currentColor" d="M384 254.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 240.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 269a16.07 16.07 0 0 0 8.84-14.36z" class="fa-primary"></path></g></svg>
              <div class="media-body">
                <h5 class="mt-0 mb-1">Nettoyage en profondeur</h5>
                <p>Lavage de tout le linge de maison.</p>
                <p>Lavage des draps à température élevée.</p>
                <p>Nettoyage vapeur des tissus du mobiliers.</p>
              </div>
            </li>
            <li class="media col-12 col-md-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sparkles" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sparkles fa-w-16 fa-2x mr-3"><g class="fa-group"><path fill="currentColor" d="M423.16 186.58L448 127l59.58-24.84a8 8 0 0 0 0-14.32L448 63 423.16 3.42a8 8 0 0 0-14.32 0L384 63l-59.58 24.84a8 8 0 0 0 0 14.32L384 127l24.84 59.58a8 8 0 0 0 14.32 0zm-14.32 136.84L384 383l-59.58 24.84a8 8 0 0 0 0 14.32L384 447l24.84 59.58a8 8 0 0 0 14.32 0L448 447l59.58-24.84a8 8 0 0 0 0-14.32L448 383l-24.84-59.58a8 8 0 0 0-14.32 0z" class="fa-secondary"></path><path fill="currentColor" d="M384 254.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 240.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 269a16.07 16.07 0 0 0 8.84-14.36z" class="fa-primary"></path></g></svg>
              <div class="media-body">
                <h5 class="mt-0 mb-1">Protocole Sanitaire</h5>
                <p>Pour éviter toute contamination croisée, le port du masque est obligatoire et une distance de sécurité sera maintenu, afin de respecter les normes de distanciation sociale exigé par le protocole sanitaire en vigueur.</p>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="$store.state.lang == 'us'">
          <p>At this special time, we make a point of ensuring the safety of our customers.</p>
          <p>Thus, we are committed to applying a deep cleaning process : a set of standards developed in partnership with experts, to be used during the COVID-19 pandemic and beyond.</p>
          <p>This procedure will be systematically carried out before each stay :</p>

          <ul class="list-unstyled row">
            <li class="media col-12 col-md-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sparkles" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sparkles fa-w-16 fa-2x mr-3"><g class="fa-group"><path fill="currentColor" d="M423.16 186.58L448 127l59.58-24.84a8 8 0 0 0 0-14.32L448 63 423.16 3.42a8 8 0 0 0-14.32 0L384 63l-59.58 24.84a8 8 0 0 0 0 14.32L384 127l24.84 59.58a8 8 0 0 0 14.32 0zm-14.32 136.84L384 383l-59.58 24.84a8 8 0 0 0 0 14.32L384 447l24.84 59.58a8 8 0 0 0 14.32 0L448 447l59.58-24.84a8 8 0 0 0 0-14.32L448 383l-24.84-59.58a8 8 0 0 0-14.32 0z" class="fa-secondary"></path><path fill="currentColor" d="M384 254.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 240.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 269a16.07 16.07 0 0 0 8.84-14.36z" class="fa-primary"></path></g></svg>
              <div class="media-body">
                <h5 class="mt-0 mb-1">Surface sanitation</h5>
                <p>Sanitation of all frequently touched surfaces, right down to the door handles.</p>
              </div>
            </li>
            <li class="media col-12 col-md-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sparkles" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sparkles fa-w-16 fa-2x mr-3"><g class="fa-group"><path fill="currentColor" d="M423.16 186.58L448 127l59.58-24.84a8 8 0 0 0 0-14.32L448 63 423.16 3.42a8 8 0 0 0-14.32 0L384 63l-59.58 24.84a8 8 0 0 0 0 14.32L384 127l24.84 59.58a8 8 0 0 0 14.32 0zm-14.32 136.84L384 383l-59.58 24.84a8 8 0 0 0 0 14.32L384 447l24.84 59.58a8 8 0 0 0 14.32 0L448 447l59.58-24.84a8 8 0 0 0 0-14.32L448 383l-24.84-59.58a8 8 0 0 0-14.32 0z" class="fa-secondary"></path><path fill="currentColor" d="M384 254.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 240.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 269a16.07 16.07 0 0 0 8.84-14.36z" class="fa-primary"></path></g></svg>
              <div class="media-body">
                <h5 class="mt-0 mb-1">Use of approvals products</h5>
                <p>Use of cleaning products approved by health experts, such as disinfectants containing at least 70% alcohol.</p>
              </div>
            </li>
            <li class="media col-12 col-md-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sparkles" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sparkles fa-w-16 fa-2x mr-3"><g class="fa-group"><path fill="currentColor" d="M423.16 186.58L448 127l59.58-24.84a8 8 0 0 0 0-14.32L448 63 423.16 3.42a8 8 0 0 0-14.32 0L384 63l-59.58 24.84a8 8 0 0 0 0 14.32L384 127l24.84 59.58a8 8 0 0 0 14.32 0zm-14.32 136.84L384 383l-59.58 24.84a8 8 0 0 0 0 14.32L384 447l24.84 59.58a8 8 0 0 0 14.32 0L448 447l59.58-24.84a8 8 0 0 0 0-14.32L448 383l-24.84-59.58a8 8 0 0 0-14.32 0z" class="fa-secondary"></path><path fill="currentColor" d="M384 254.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 240.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 269a16.07 16.07 0 0 0 8.84-14.36z" class="fa-primary"></path></g></svg>
              <div class="media-body">
                <h5 class="mt-0 mb-1">Deep cleaning</h5>
                <p>Washing of all household linen.</p>
                <p>Washing of sheets at high temperature.</p>
                <p>Steam cleaning of furniture fabrics.</p>
              </div>
            </li>
            <li class="media col-12 col-md-6">
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="sparkles" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sparkles fa-w-16 fa-2x mr-3"><g class="fa-group"><path fill="currentColor" d="M423.16 186.58L448 127l59.58-24.84a8 8 0 0 0 0-14.32L448 63 423.16 3.42a8 8 0 0 0-14.32 0L384 63l-59.58 24.84a8 8 0 0 0 0 14.32L384 127l24.84 59.58a8 8 0 0 0 14.32 0zm-14.32 136.84L384 383l-59.58 24.84a8 8 0 0 0 0 14.32L384 447l24.84 59.58a8 8 0 0 0 14.32 0L448 447l59.58-24.84a8 8 0 0 0 0-14.32L448 383l-24.84-59.58a8 8 0 0 0-14.32 0z" class="fa-secondary"></path><path fill="currentColor" d="M384 254.64a16.06 16.06 0 0 0-8.84-14.33l-112.57-56.39-56.28-112.77c-5.44-10.87-23.19-10.87-28.62 0l-56.28 112.77L8.84 240.31a16 16 0 0 0 0 28.67l112.57 56.39 56.28 112.77a16 16 0 0 0 28.62 0l56.28-112.77L375.16 269a16.07 16.07 0 0 0 8.84-14.36z" class="fa-primary"></path></g></svg>
              <div class="media-body">
                <h5 class="mt-0 mb-1">Sanitary Protocol</h5>
                <p>To avoid any cross-contamination, wearing a mask is mandatory and a safety distance will be maintained, in order to respect the standards of social distancing required by the health protocol in force.</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer" v-if="$store.state.lang == 'fr'">
        <p>Consultez nos <a href="https://www.airbnb.fr/help/article/2809/questce-que-le-processus-de-nettoyage-renforc%C3%A9-en-5%C2%A0%C3%A9tapes-dairbnb%C2%A0" target="blank">check-lists détaillées</a> pour le nettoyage de chaque pièce</p>
      </div>
      <div class="modal-footer" v-if="$store.state.lang == 'us'">
        <p>Consult our <a href="https://www.airbnb.com/help/article/2809/what-is-airbnbs-5step-enhanced-cleaning-process?locale=en&_set_bev_on_new_domain=1611773577_MzVjOGZlZmZhNWYz" target="blank">detailed checklists</a> for the cleaning of each room</p>
      </div>
    </div>
  </div>
</div>

  </div>
</template>

<style scoped>

.bandeau{
  width: 100%;
  height: 60vh;
  margin-bottom: 100px;
  background-image: url("../assets/leboisflotte/bandeau.jpg");
  background-position: bottom;
  background-size:cover;
  background-attachment:fixed;
}

.voileGris{
  width: 100%;
  height: 60vh;
  background-color: #848b9a54;
  position: absolute;
  top:0;
  text-align: center;
}

.voileGris h2{
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 70px;
  text-shadow: black 5px 5px 5px;
}

@media screen and (max-width: 800px) {
  .voileGris h2{
    top: 50%;
    left: 5%;
    transform: translate(0, -50%);
    font-size: 40px;
  }
}

.presentation{
  box-shadow: black 0px 2px 4px;
}

.presentation .bloc{
  min-height: 500px;
}

.presentation .blocText{
  padding-top: 20px;
  padding-left: 30px;
}

.presentation .blocText h2{
  margin-bottom: 30px;
}

.presentation .blocImage{
  background-image: url("../assets/leboisflotte/2.jpg");
  background-size: cover;
  background-position: center;
}

.equipement{
  margin-top: 30px;
  margin-bottom: 50px;
}

.equipement h2{
  margin-bottom: 30px;
}

.equipement .ligne{
  height: 28px;
  padding-left: 10px;
}

.equipement .ligneGrise{
 background-color: rgb(214, 214, 214);
}

.blocEquipement{
  margin-bottom: 30px;
}

.conteneurSvg{
  display: inline-block;
  width: 30px;
}

.btn-perso{
  color: #fff;
  background-color: #5C616B;
  border-radius: 0;
}

.btn-perso:hover{
  color: #fff;
  background-color: #848B9A;
}
/* Masonry ------------------ */

.masonry{
  margin-bottom: 30px;
}

.cols {
  column-count: 4;
  column-gap: 10px;
  column-width: 30%;
}

@media screen and (max-width: 800px) {
  .cols {
    column-count: 3;
  }
}

@media screen and (max-width: 512px) {
  .cols {
    column-count: 2;
  }
}

.box {
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;
}

.box:hover{
  box-shadow: black 3px 3px 3px;
}

.box img{
  width: 100%;
  transition: 0.3s;
}

/* Modale --------------------*/

h3{
  font-family: 'Kaushan Script', cursive;
}

#lightbox .modal-dialog{
  max-width: 50%!important;
}

#equipModal .modal-dialog{
  max-width: 60%!important;
}

#Covid19Modal .modal-dialog{
  max-width: 60%!important;
}

#Covid19Modal .modal-footer p{
  width: 100%;
}

#Covid19Modal svg{
  color: #0090dd;
}

@media screen and (max-width: 600px) {
  #equipModal .modal-dialog {
    max-width: initial!important;
  }

  #lightbox .modal-dialog {
    max-width: initial!important;
  }

  #Covid19Modal .modal-dialog{
    max-width: initial!important;
  }
}

#lightbox .modal-dialog img{
  width: 100%;
}

#lightbox .modal-footer p{
  width: 100%;
}

#equipModal h3{
  margin-top: 50px;
  margin-bottom: 20px;
}

.pgris{
  color: #999999;
}

/* Tableau tarifs ------------------------------------------------------*/
table{
  width: 80%;
  margin: auto;
}

.grille_tarifs > table > tbody > tr:nth-child(2n) {
    background: #efefef;
}

.type_de_durees {
  text-align: center;
  font-weight: 700;
}

b {
  font-weight: 700;
}

.td_tarif {
  text-align: center;
  color: #0077b9;
  font-weight: 700;
}

/* Map ---------------------------------------------------*/

.map{
  margin-top: 40px;
}

.map h2{
  margin-bottom: 30px;
}

.infoMap{
  width: 200px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  margin: 10px 0 0 50px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 2px;
  padding: 10px;
  font-size: 14px;
}

.infoMap a{
  font-weight: bold;
}

.adresse{
  font-weight: bold;
}

/* Avis --------------------------------------------------*/
.quote{
  margin-top: 100px;
  min-height: 200px;
}

.avis{
  font-size: 20px;
  font-style: italic;
  width: 70%;
  margin: auto;
}

.carousel-indicators li{
  background-color: #5C616B;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%5c616b52' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%5c616b52' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

/* Infos -------------------------------------------------*/

.infos{
  width: 100%;
  min-height: 400px;
  background: url('../assets/portenbessin.png');
  background-size: cover;
  margin: 50px 0;
  display: flex;
  padding: 10px;
}

.infoBloc{
  width: 80%;
  min-height: 320px;
  background-color: rgb(255, 255, 255);
  margin: auto;
  display: flex; /* contexte sur le parent */
  flex-direction: column; /* direction d'affichage verticale */
  justify-content: center; /* alignement vertical */
  padding: 30px 0;
}

.infoBloc h3, .infoBloc p{
  font-family: 'Kaushan Script', cursive;
}

.infoBloc p{
  font-size: 25px;
}

.conteneurLogo{
  width: 80%;
  margin: auto;
  text-align: center;
}

.logoImg{
  display: inline-block;
  margin: 30px;
}

.logoImg img{
  width: 200px;
}

@media screen and (max-width: 500px) {
  .logoImg img{
    width: 100px;
  }
}

.infos .btn{
  width: 170px;
  margin: auto;
}
</style>

<script>
export default {
  name: 'Leboisflotte',
  data () {
    return {
      publicPath: process.env.BASE_URL,
      pathImage: null,
      descrImage: null,
      images: {
        img1: {
          path: require('../assets/leboisflotte/1.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img2: {
          path: require('../assets/leboisflotte/2.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img3: {
          path: require('../assets/leboisflotte/3.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img4: {
          path: require('../assets/leboisflotte/4.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img5: {
          path: require('../assets/leboisflotte/5.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img6: {
          path: require('../assets/leboisflotte/6.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img7: {
          path: require('../assets/leboisflotte/7.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img8: {
          path: require('../assets/leboisflotte/8.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img9: {
          path: require('../assets/leboisflotte/9.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img10: {
          path: require('../assets/leboisflotte/10.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img11: {
          path: require('../assets/leboisflotte/11.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img12: {
          path: require('../assets/leboisflotte/12.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img13: {
          path: require('../assets/leboisflotte/13.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img14: {
          path: require('../assets/leboisflotte/14.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img15: {
          path: require('../assets/leboisflotte/15.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        },
        img16: {
          path: require('../assets/leboisflotte/16.jpg'),
          descr: {
            fr: '',
            us: ''
          }
        }
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    galerie (path, descr) {
      this.pathImage = path
      switch (this.$store.state.lang) {
        case 'fr':
          this.descrImage = descr.fr
          break
        case 'us':
          this.descrImage = descr.us
          break
        default:
          this.descrImage = descr.fr
          break
      }
    },
    form () {
      this.$store.commit('MUTATION_GITE', 'lbf')
      this.$router.push({ path: 'contact' })
    }
  }
}
</script>
