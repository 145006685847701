<template>
  <div class="contact">
    <div class="bandeau"></div>
    <div class="voileGris">
      <h2><span v-if="$store.state.lang == 'fr'">Nous contacter</span><span v-if="$store.state.lang == 'us'">Contact us</span></h2>
    </div>

      <div class="contact2 d-flex justify-content-center align-items-center">

        <form class="align-middle">
          <label for="nomGite"><span v-if="$store.state.lang == 'fr'">Gite concerné :</span><span v-if="$store.state.lang == 'us'">Gite concerned :</span></label>
          <select id="gite" name="gite">
            <option id="lpdl">Les Pieds Dans l'Eau</option>
            <option id="sle">Sous Les Embruns</option>
            <option id="ledp">L'entre Deux Phares</option>
            <option id="lc1">La Capitainerie 1</option>
            <option id="lc2">La Capitainerie 2</option>
            <option id="lbf">Le Bois Flotté</option>
            <option id="lmdh">La Maison D'Harold</option>
          </select>

          <label for="dateArrivee"><span v-if="$store.state.lang == 'fr'">Date d'arrivée* :</span><span v-if="$store.state.lang == 'us'">Arrival date* :</span></label>
          <input type="date" id="arrive" name="voyage-arrive" required>

          <label for="dateDépart"><span v-if="$store.state.lang == 'fr'">Date de départ* :</span><span v-if="$store.state.lang == 'us'">Date of departure* :</span></label>
          <input type="date" id="depart" name="voyage-depart" required>

          <label for="nbAdultes"><span v-if="$store.state.lang == 'fr'">Adultes *:</span><span v-if="$store.state.lang == 'us'">Adults *:</span></label>
          <input type="number" id="adultes" name="adultes" min="1" required>

          <label for="nbEnfants"><span v-if="$store.state.lang == 'fr'">Enfants* :</span><span v-if="$store.state.lang == 'us'">Children *: </span></label>
          <input type="number" id="enfants" name="enfants" min="0" required>

          <h5><span v-if="$store.state.lang == 'fr'">Informations complémentaires :</span><span v-if="$store.state.lang == 'us'">Further information : </span></h5>

          <input type="text" name="nom" id="nom" placeholder="Name*" maxlength="10" required />

          <input type="email" name="email" id="email" placeholder="Email*" required />

          <textarea name="msg" id="msg" placeholder="Message*" required></textarea>

          <vue-hcaptcha @verify="verify()" class="text-center my-1" sitekey="560d9cf3-57b7-42ab-b4e4-b4ddff9fe1a5"></vue-hcaptcha>

          <button class="btn-perso1" type="reset"><span v-if="$store.state.lang == 'fr'">Réinitialiser</span><span v-if="$store.state.lang == 'us'">Reset</span></button><button id="btnSubmit" class="btn-perso2" type="button" @click="submit()" disabled><span v-if="$store.state.lang == 'fr'">Envoyer</span><span v-if="$store.state.lang == 'us'">Submit</span></button>
          <div class="block"><p><span v-if="$store.state.lang == 'fr'">Les champs marqué d'un * sont obligatoires.</span><span v-if="$store.state.lang == 'us'">Fields marked with * are required.</span></p></div>

        </form>

      </div>

  </div>
</template>

<style scoped>
.bandeau{
  width: 100%;
  height: 60vh;
  background-image: url("../assets/tour.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.voileGris{
  width: 100%;
  height: 60vh;
  background-color: #848b9a54;
  position: absolute;
  top:0;
  text-align: center;
}

.voileGris h2{
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(0, -50%);
  color: #fff;
  font-size: 70px;
  text-shadow: black 5px 5px 5px;
}

@media screen and (max-width: 800px) {
  .voileGris h2{
    top: 50%;
    left: 5%;
    transform: translate(0, -50%);
    font-size: 40px;
  }
}

.contact2{
  min-height: 100vh;
}

form{
  width: 30%;
}

input, textarea, select{
  width: 100%;
  margin: 0 0 10px 0;
}

form button{
  width: 45%;
}

.btn-perso1{
  float:left;
}

.btn-perso2{
  float: right;
}

.block{
  margin-top : 50px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  form{
    width: 90%;
  }
}
</style>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha'
import axios from 'axios'

export default {
  name: 'Contact',
  components: { VueHcaptcha },
  data () {
    return {}
  },
  mounted () {
    window.scrollTo(0, 0)
    this.selectGite()
  },
  methods: {
    selectGite () {
      switch (this.$store.state.gite) {
        case 'lpdl':
          document.getElementById('lpdl').setAttribute('selected', true)
          break
        case 'sle':
          document.getElementById('sle').setAttribute('selected', true)
          break
        case 'ledp':
          document.getElementById('ledp').setAttribute('selected', true)
          break
        case 'lc1':
          document.getElementById('lc1').setAttribute('selected', true)
          break
        case 'lc2':
          document.getElementById('lc2').setAttribute('selected', true)
          break
        case 'lbf':
          document.getElementById('lbf').setAttribute('selected', true)
          break
        case 'lmdh':
          document.getElementById('lmdh').setAttribute('selected', true)
          break
        default:
          document.getElementById('lpdl').setAttribute('selected', true)
          break
      }
    },
    verify () {
      document.getElementById('btnSubmit').disabled = false
    },
    submit () {
      const gite = 'Gite : ' + document.getElementById('gite').value
      const arrive = 'Date arrivée : ' + document.getElementById('arrive').value
      const depart = 'Date départ : ' + document.getElementById('depart').value
      const adultes = 'Nombre adultes : ' + document.getElementById('adultes').value
      const enfants = 'Nombre enfants : ' + document.getElementById('enfants').value
      const nom = 'Nom : ' + document.getElementById('nom').value
      const email = 'Email : ' + document.getElementById('email').value
      const msg = 'Message : ' + document.getElementById('msg').value

      if (document.getElementById('gite').value !== '') {
        this.giteValide = true
      } else {
        document.getElementById('gite').setCustomValidity('Champ invalide')
        this.giteValide = false
      }
      if (/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(document.getElementById('arrive').value)) {
        this.arriveValide = true
      } else {
        document.getElementById('arrive').setCustomValidity('Champ invalide')
        this.arriveValide = false
        console.log(document.getElementById('arrive').value)
      }
      if (/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(document.getElementById('depart').value)) {
        this.departValide = true
      } else {
        document.getElementById('depart').setCustomValidity('Champ invalide')
        this.departValide = false
      }
      if (/^[0-9]+$/.test(document.getElementById('adultes').value)) {
        this.adultesValide = true
      } else {
        document.getElementById('adultes').setCustomValidity('Champ invalide')
        this.adultesValide = false
      }
      if (/^[0-9]+$/.test(document.getElementById('enfants').value)) {
        this.enfantsValide = true
      } else {
        document.getElementById('enfants').setCustomValidity('Champ invalide')
        this.enfantsValide = false
      }
      if (/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(document.getElementById('nom').value)) {
        this.nomValide = true
      } else {
        document.getElementById('nom').setCustomValidity('Champ invalide')
        this.nomValide = false
      }
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(document.getElementById('email').value)) {
        this.emailValide = true
      } else {
        document.getElementById('email').setCustomValidity('Champ invalide')
        this.emailValide = false
      }
      if (document.getElementById('msg').value !== '') {
        this.msgValide = true
      } else {
        document.getElementById('msg').setCustomValidity('Champ invalide')
        this.msgValide = false
      }

      if (this.giteValide === true && this.arriveValide === true && this.departValide === true && this.adultesValide === true && this.enfantsValide === true && this.nomValide === true && this.emailValide === true && this.msgValide === true) {
        const message = email + '\n' + gite + '\n' + arrive + '\n' + depart + '\n' + adultes + '\n' + enfants + '\n' + nom + '\n\n' + msg
        const data = {
          email,
          message
        }
        axios
          .post('https://lentredeuxphares.fr/api/email', data)
          .then(
            response => (console.log(response)),
            alert('Votre message a bien été envoyé !'),
            this.$router.push({ path: '/' })
          )
          .catch(function (error) {
            alert(error)
            console.log(error)
          })
      }
    }
  }
}
</script>
